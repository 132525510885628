import config from '@/services/config'
import axios from 'axios'
import {tokenExpired} from '@/auth/utils'

export default {
  toggleLocation(campaign_uuid, markers, always_add = false) {
    const data = { markers, always_add }
    return axios.post(`${config.urlCore}/api/v3/realty/${campaign_uuid}/toggle/locations/`,
      data,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  },
  getStates() {
    return axios.get(`${config.urlCore}/api/v3/realty/states/`, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  },
  getCategories() {
    return axios.get(`${config.urlCore}/api/v3/realty/categories/`, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  },
  searchLocations(filters, page = 1) {
    return axios.post(`${config.urlCore}/api/v3/realty/search/locations/?page=${page}`,
      filters,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  },
  getLocationIn(location_uuid) {
    return axios.get(`${config.urlCore}/api/v3/realty/location-in/${location_uuid}`,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      });
  },
}
